/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import WorkforceProductivity from 'routes/work/workforce-productivity'

// Meta Component
import Meta from 'components/Meta/Meta'

// WorkforceProductivity Route
export default WorkforceProductivity

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query WorkforcePageQuery {
    page: datoCmsPage(slug: { eq: "workforce-productivity" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { w: "450", fm: "jpg", auto: "compress", maxW: 450 }
        )
      }
    }
    caseStudies: allDatoCmsCasestudy(
      limit: 6
      filter: {
        tags: { elemMatch: { slug: { eq: "mobile-working" } } }
        isHidden: { eq: false }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          heroBannerImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "1280"
                h: "960"
                maxW: 800
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          client {
            companyName
          }
        }
      }
    }
  }
`
