import React from 'react'
import { Section, Columns } from 'react-bulma-components'

function Customers() {
  return (
    <Section>
      <Columns>
        <Columns.Column className="is-10 is-offset-1">
          <img
            src="https://www.datocms-assets.com/20955/1579120367-clients.png?fm=png"
            alt=""
          />
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default Customers
