import PropTypes from 'prop-types'
import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'

import CaseStudyCard from 'components/case-study-card'

function CasestudyBlog({ caseStudies, title }) {
  return (
    <Section className="is-small">
      <Container className="content">
        <Columns className="is-centered">
          <Columns.Column className="is-6 ">
            <h2 className="has-text-centered title is-size-3-mobile">
              More <span className="has-text-purple">case studies </span>
              {title ? `for ${title}` : ''}
            </h2>
          </Columns.Column>
        </Columns>
        <Columns className="is-centered">
          <Columns.Column className="is-10">
            <Columns className="is-multiline is-centered">
              {caseStudies.edges.map(({ node: caseStudy }) => (
                <Columns.Column key={caseStudy.id} className="is-4">
                  <CaseStudyCard caseStudy={caseStudy} />
                </Columns.Column>
              ))}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
CasestudyBlog.propTypes = {
  caseStudies: PropTypes.object,
  title: PropTypes.string,
}
export default CasestudyBlog
