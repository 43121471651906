import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function ArrowLink(props) {
  return (
    <Link to={props.to} className={`arrow-link ${props.className}`}>
      <span className="icon is-medium">
        <i
          className={
            props.iconClass ? props.iconClass : 'fas fa-arrow-circle-right'
          }
        />
      </span>
      <span className="is-size-6">{props.children}</span>
    </Link>
  )
}

ArrowLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  iconClass: PropTypes.string,
  children: PropTypes.node,
}

export default ArrowLink
