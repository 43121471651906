import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'
import ArrowLink from './link-with-arrow'

function CaseStudyHero(props) {
  return (
    <div>
      {props.flipped ? (
        <Columns className="content is-vcentered">
          <Columns.Column className="is-6 is-offset-1 content ">
            {props.logo ? (
              <img src={props.logo} alt="Logo" className="mt-6" />
            ) : (
              <span />
            )}
            <h2
              className="title mt-2 is-size-3-mobile"
              dangerouslySetInnerHTML={{ __html: props.titleHtml }}
            />
            {props.excerpt ? (
              <p className="is-top-marginless">{props.excerpt}</p>
            ) : (
              <span className="is-hidden" />
            )}
            <p>
              <ArrowLink
                to={props.to}
                className={`${props.className} is-size-4`}
              >
                {props.toText ? props.toText : 'Read the case study'}
              </ArrowLink>
            </p>
          </Columns.Column>
          <Columns.Column className="is-4 is-hidden-mobile">
            <img src={props.mainImage} alt={props.excerpt} />
          </Columns.Column>
        </Columns>
      ) : (
        <Columns className="content is-vcentered">
          <Columns.Column className="is-4 is-hidden-mobile">
            <img src={props.mainImage} alt={props.excerpt} className="mt-6" />
          </Columns.Column>
          <Columns.Column className="is-6">
            {props.logo ? <img src={props.logo} alt="Logo" /> : <span />}
            <h2
              className="title mt-2 is-size-3-mobile"
              dangerouslySetInnerHTML={{ __html: props.titleHtml }}
            />
            {props.excerpt ? (
              <p className="is-top-marginless">{props.excerpt}</p>
            ) : (
              <span className="is-hidden" />
            )}
            <p>
              <ArrowLink
                to={props.to}
                className={`${props.className} is-size-4`}
              >
                {props.toText ? props.toText : 'Read the case study'}
              </ArrowLink>
            </p>
          </Columns.Column>
        </Columns>
      )}
    </div>
  )
}

CaseStudyHero.propTypes = {
  flipped: PropTypes.bool,
  logo: PropTypes.any,
  toText: PropTypes.string,
  className: PropTypes.string,
  excerpt: PropTypes.string,
  to: PropTypes.string,
  mainImage: PropTypes.string,
  titleHtml: PropTypes.any,
}

export default CaseStudyHero
