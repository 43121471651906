import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Hero, Columns, Container } from 'react-bulma-components'

function tomQuote() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <hr />
          <Columns className="is-inline-flex">
            <Columns.Column className="is-3 is-offset-1">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120440-tom.jpg?w=300&fit=facearea&facepad=5&mask=ellipse"
                alt="Quote By Tom - Pocketworks"
              />
            </Columns.Column>
            <Columns.Column className="is-6 ">
              <br />
              <br />
              <br />
              <p className="is-size-4">
                "Our clients often ask: Do I build or buy? I help them look at
                the five-year picture so they can make an informed decision."
              </p>
              <p className="tag is-medium">Tom, Mobile Strategy Specialist</p>
            </Columns.Column>
          </Columns>
          <hr />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default tomQuote
