import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Columns, Container, Content, Hero } from 'react-bulma-components'

function WorkHero({ page }) {
  return (
    <Hero className="is-small is-widescreen">
      <Hero.Body>
        <Container className="pb-5">
          <Content>
            <Columns className="is-centered has-text-centered">
              <Columns.Column className="is-9">
                <h1
                  className="title is-size-1-widescreen is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text has-text-grey-darker"
                  dangerouslySetInnerHTML={{ __html: page.title }}
                />

                <p
                  className="is-size-4"
                  dangerouslySetInnerHTML={{ __html: page.excerpt }}
                />
              </Columns.Column>
            </Columns>
          </Content>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
WorkHero.propTypes = {
  page: PropTypes.object,
}
export default WorkHero
