import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Card, Columns } from 'react-bulma-components'

// Components
import ArrowLink from './link-with-arrow'

function CaseStudyCard(props) {
  if (props.jumbo) {
    return (
      <div
        className={`jumbo-casestudy${
          props.hasBorderTop === true ? ' has-border-topx' : ''
        }`}
      >
        <Columns className="content">
          <Columns.Column className="is-12">
            <Link to={`/case-studies/${props.caseStudy.slug}`}>
              {props.caseStudy.heroBannerImage.gatsbyImageData ? (
                <GatsbyImage
                  image={props.caseStudy.heroBannerImage.gatsbyImageData}
                  alt={props.caseStudy.title || 'Pocketworks'}
                  className="is-rounded has-shadow-top-bottom"
                />
              ) : (
                <img
                  src="https://www.datocms-assets.com/20955/1579120771-dscf7651fotor.jpg?fm=jpg&w=1280&h=960&fit=clip"
                  alt="Placeholder"
                />
              )}
            </Link>
          </Columns.Column>
          <Columns.Column className="is-9 is-12-tablet is-offset-0">
            <p className="has-text-dark is-size-5 mb-2 has-text-weight-semibold">
              {props.caseStudy.client.companyName}
            </p>
            <Link to={`/case-studies/${props.caseStudy.slug}`}>
              <p className="title is-size-5 mt-0 pt-0">
                {props.caseStudy.title}
              </p>
            </Link>
            <p dangerouslySetInnerHTML={{ __html: props.caseStudy.excerpt }} />
            <p className="is-pulled-left">
              <ArrowLink
                className={`is-hidden is-size-5 has-text-bold ${props.caseStudy.slug}`}
                to={`/case-studies/${props.caseStudy.slug}`}
              >
                Read full article
              </ArrowLink>
            </p>
          </Columns.Column>
        </Columns>
      </div>
    )
  }
  if (props.compact) {
    return (
      <Card className="is-case-study is-equal-height">
        <Link to={`/case-studies/${props.caseStudy.slug}`}>
          {props.caseStudy.heroBannerImage.gatsbyImageData ? (
            <div className="card-image">
              <GatsbyImage
                image={props.caseStudy.heroBannerImage.gatsbyImageData}
                alt={props.caseStudy.title || 'Pocketworks'}
              />
            </div>
          ) : (
            <img
              src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&w=250"
              alt="Placeholder"
            />
          )}
        </Link>
        <Card.Content>
          <p className="is-uppercase is-size-7 is-hidden">
            <span className="has-text-purple">Case Study</span>{' '}
            {props.caseStudy.client.companyName}
          </p>
          <h5 className="is-top-marginless is-size-6">
            <Link
              to={`/case-studies/${props.caseStudy.slug}`}
              dangerouslySetInnerHTML={{ __html: props.caseStudy.title }}
            />
          </h5>
        </Card.Content>
        <Card.Footer />
      </Card>
    )
  }
  return (
    <Card className="is-case-study is-equal-height">
      <Link to={`/case-studies/${props.caseStudy.slug}`}>
        {props.caseStudy.heroBannerImage.gatsbyImageData ? (
          <div className="card-image">
            <GatsbyImage
              image={props.caseStudy.heroBannerImage.gatsbyImageData}
              title={props.caseStudy.title}
              alt={props.caseStudy.title || 'Pocketworks'}
            />
          </div>
        ) : (
          <img
            src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&w=250"
            alt="Placeholder"
          />
        )}
      </Link>
      <Card.Content>
        <p className="is-uppercase is-size-7">
          <span className="has-text-purple">Case Study</span>{' '}
          {props.caseStudy.client.companyName}
        </p>
        <h3 className="is-top-marginless mt-0">
          <Link
            to={`/case-studies/${props.caseStudy.slug}`}
            dangerouslySetInnerHTML={{ __html: props.caseStudy.title }}
          />
        </h3>
      </Card.Content>
      <Card.Footer>
        <ArrowLink
          className="is-size-5 card-footer-item"
          to={`/case-studies/${props.caseStudy.slug}`}
        >
          Read Case Study
        </ArrowLink>
      </Card.Footer>
    </Card>
  )
}

CaseStudyCard.propTypes = {
  jumbo: PropTypes.bool,
  compact: PropTypes.bool,
  hasBorderTop: PropTypes.bool,
  caseStudy: PropTypes.object,
}

export default CaseStudyCard
