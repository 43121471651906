import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Componnets
import CaseStudyHero from 'components/case-study-hero'

function Clients2() {
  return (
    <Section>
      <Container>
        <CaseStudyHero
          className="has-text-info"
          logo="https://www.datocms-assets.com/20955/1579120361-pgmol-logo-white.png"
          mainImage="https://www.datocms-assets.com/20955/1579449491-pgmo-mockup.jpeg?w=400&fm=jpg"
          titleHtml="<span class='has-text-info'>500 referees</span> can’t be wrong"
          to="/case-studies/pgmol"
          toText="Find out why"
        />
        <img
          src="https://www.datocms-assets.com/20955/1579120592-link.png?w=1800"
          alt=""
        />
        <CaseStudyHero
          className="has-text-success"
          flipped
          logo="https://www.datocms-assets.com/20955/1594904114-hen-logo.png?w=200"
          mainImage="https://www.datocms-assets.com/20955/1606943160-heneghans-mockup.png?w=400&fm=jpg"
          titleHtml="<span class='has-text-success'>Reducing paperwork</span> by 70% in construction."
          to="/case-studies/digital-tools-to-help-a-utilities-company-scale"
        />
      </Container>
    </Section>
  )
}

export default Clients2
