import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Tabs } from 'react-bulma-components'

function WorkNav() {
  return (
    <Tabs className="tabs is-medium is-centered">
      <TabLink to="/work" activeClassName="is-active" partiallyActive={false}>
        All Work
      </TabLink>
      <TabLink
        to="/work/workforce-productivity"
        activeClassName="is-active"
        partiallyActive
      >
        Employee Apps
      </TabLink>
      <TabLink
        to="/work/digital-transformation"
        activeClassName="is-active"
        partiallyActive
      >
        Consumer Apps
      </TabLink>
      <TabLink to="/work/device-manufacturers">
        Devices &amp; Instruments
      </TabLink>
    </Tabs>
  )
}

function TabLink(props) {
  const [className, setClassName] = React.useState('')
  const isActive = ({ isCurrent }) => isCurrent && setClassName('is-active')
  return (
    <li className={className}>
      <Link
        to={props.to}
        partiallyActive
        activeClassName="is-active"
        getProps={isActive}
      >
        {props.children}
      </Link>
    </li>
  )
}

TabLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

export default WorkNav
