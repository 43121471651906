import React from 'react'
import { Link } from 'gatsby'
import { Columns, Container, Hero } from 'react-bulma-components'

function BetterWorkforce() {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="content">
          <Columns className="is-centered">
            <Columns.Column className="is-10 has-text-centered">
              <h2 className="is-size-3-mobile">
                Three ways to make{' '}
                <span className="has-text-info">better workforce apps</span>
              </h2>
              <br />
              <br />
            </Columns.Column>
          </Columns>
          <Columns className="is-centered">
            <Columns.Column className="is-3">
              <h3>Design for people</h3>
              <p>
                Professionals need tools that consider their needs to help them
                feel engaged, appreciated and motivated. Our design approach
                directly involves the people doing the work so that we can
                ensure the results help rather than hinder.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Follow the work</h3>
              <p>
                Work gets done in the office, on the road and on-site. We
                deliver solutions for all environments; apps for mobile working
                and desktop portals for office work. And we tie it together with
                databases, APIs and integrations.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Be ready for change</h3>
              <p>
                To get the real value from any IT investment you need to
                monitor, improve and adapt. We support you in measuring and
                learning whilst keeping it secure, healthy and available
                24/7/365.
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <p>
                <br />
                <Link
                  to="/"
                  className="button is-primary is-outlined is-leafy is-medium is-primary"
                >
                  View all our services
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default BetterWorkforce
