import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import WorkNav from 'components/work-nav'
import ArrowLink from 'components/link-with-arrow'
import Layout from 'components/layout'
import Clients2 from 'components/clients2'
import TomQuote from 'components/tomQuote'
import Customers from 'components/customers'
import CasestudyBlog from 'components/casestudyBlog'
import WorkHero from 'components/workHero'

// Page Components
import BetterWorkforce from './components/better-workforce'

function WorkforceProductivity({ data: { page, caseStudies } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <WorkNav />
      <WorkHero page={page} />
      <hr />
      <Clients2 />
      <TomQuote />
      <BetterWorkforce />
      <hr />
      <CasestudyBlog title="workforce apps" caseStudies={caseStudies} />
      <Customers />
      <Section>
        <Container className="has-text-centered">
          <ArrowLink to="/work/services" className="is-info">
            Next: Services &amp; Packages
          </ArrowLink>
        </Container>
      </Section>
    </Layout>
  )
}

WorkforceProductivity.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WorkforceProductivity
